export class BaseError extends Error {
    constructor (message, skipSentryEvent = false) {
        super(message)
        this.name = this.constructor.name
        this.skipSentryEvent = skipSentryEvent
    }
}

export class PromiseRejectionError extends BaseError {
    constructor (message = 'Unexpected error', skipSentryEvent = true) {
        super(message, skipSentryEvent)
    }
}

export class FieldError extends BaseError {
    constructor (errorsMap, skipSentryEvent = true) {
        super('', skipSentryEvent)
        this.errorsMap = errorsMap
    }
}

export class CanceledOperation extends BaseError {
    constructor (message, skipSentryEvent = true) {
        super(message, skipSentryEvent)
    }
}
