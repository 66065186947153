import ko from 'knockout'

// register sub-components
import './item-group'
import './item-group-voucher'
import './item-offer-discounts'
import './item'

import html from 'components/basket-content.html'

class BasketContentViewModel {
    constructor (params) {
        params = params || {}
        this._basket = ko.observable(params.basket || null)
        this.basket = ko.pureComputed(() => ko.unwrap(this._basket()))
        this.isReadOnly = params.readOnly || false
        this.isInline = params.inline || false
        this.groups = ko.observableArray([
            'yearly-subscription', 'monthly-subscription', 'co-term-existing', 'training'
        ])
        this.errors = ko.observableArray()

        this.loading = ko.pureComputed(() => {
            return this.basket() === undefined || (this.basket() && this.basket().busy)
        })

        this.showBasketEmptyMessage = ko.pureComputed(() => {
            return this.basket() && this.basket().isEmpty && !this.isReadOnly
        })

        window.basket = this // todo: for testing
    }

    getGroupComponent () { // receives groupCode
        return 'VW.Components.BasketGroup'
    }
}

ko.components.register('VW.Components.BasketContent', {
    viewModel: BasketContentViewModel,
    template: html
})
