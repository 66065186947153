import ko from 'knockout'
import { extendObservable as mobxExtendObservable, reaction, action } from 'mobx'

export class ObservableField {
    constructor (defaultValue, errorAttribute = null) {
        this.defaultValue = defaultValue
        this.errorAttribute = errorAttribute
    }
}

const makeObservableField = (target, key) => {
    const privateKey = '_' + key
    const trigger = ko.observable()

    if (Array.isArray(target[privateKey])) {
        // for arrays, we need to subscribe to all item changes and notify for KO
        reaction(
            () => target[privateKey].map(item => item),
            () => window.setTimeout(() => trigger.notifySubscribers(target[privateKey]), 0)
        )
    }

    Object.defineProperty(target, key, {
        get: () => {
            trigger()
            return target[privateKey]
        },
        set: value => {
            target[privateKey] = value
            if (Array.isArray(target[privateKey])) {
                // not sure why but some ko computeds don't reevaluate if the trigger is executed right away
                // I've only seen it affect arrays so far
                window.setTimeout(() => trigger(value), 0)
            } else {
                trigger(value)
            }
        }
    })
}

export const extendObservable = (target, properties) => {
    target.errorToFieldMap = target.errorToFieldMap || {}

    for (let key in properties) {
        let value = properties[key]
        let errorAttribute = null
        if (value instanceof ObservableField) {
            errorAttribute = value.errorAttribute
            value = value.defaultValue
        }

        mobxExtendObservable(target, {['_' + key]: value})
        makeObservableField(target, key)

        if (errorAttribute) {
            mobxExtendObservable(target, {['_' + key + 'Errors']: null})
            makeObservableField(target, key + 'Errors')
            target.errorToFieldMap[errorAttribute] = key
        }
    }

    let setAction = 'set'
    if (properties && !target.hasOwnProperty(setAction)) {
        let actionDefinition = {}
        actionDefinition[setAction] = action
        mobxExtendObservable(target, actionDefinition)
        target[setAction] = (updates) => {
            for (let key in updates) {
                if (target.hasOwnProperty(key)) {
                    target[key] = updates[key]
                }
            }
        }
    }
}
