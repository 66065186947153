import ko from 'knockout'
import 'view-models/components/price'
import 'components/bindings'
import BasketApi from 'view-models/basket/basket'
import '../../scss/components/basket.scss'
import './i18n'
import '../utils/numeral-config'
import '../utils/locale-config'

let running = false
function run () {
    if (running) {
        return
    }

    const body = document.getElementsByTagName('body')[0]
    const cartContainer = document.createElement('div')
    cartContainer.setAttribute('data-bind', 'component: "VW.Components.Basket"')
    body.appendChild(cartContainer)

    ko.applyBindings({}, cartContainer)

    running = true
}

if (document.readyState === 'complete' || document.readyState === 'interactive') {
    setTimeout(run, 0)
} else {
    window.addEventListener('load', run)
}

window.VW = window.VW || {}
window.VW.Components = window.VW.Components || {}
window.VW.Components.Basket = new BasketApi()
