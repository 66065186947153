import {makeObservable, action, computed} from 'mobx'
import {extendObservable} from './mobx-ko-proxy'
import axios from 'utils/axios'
import {parseUrl, addUrlQueryParams} from 'utils/query-params'
import {CanceledOperation} from 'utils/errors'

export class Repository extends EventTarget {
    constructor (DataClass, baseUrl, repositorySubscriptions) {
        super()
        // todo: maybe add a max size
        makeObservable(this, {
            sort: action,
            _setItems: action,
            _deleteData: action,
            _saveItemData: action,
            _saveItemDataCore: action,
            // even though _saveItemData is already an action,
            // we want _saveData as in order to batch multiple updates together
            _saveData: action,
            _setAllLoaded: action,
            _setAllExtraData: action,
            items: computed
        })

        extendObservable(this, {
            _items: [],
            _allLoaded: false,
            _allExtraData: {}
        })

        this.baseUrl = baseUrl
        this._map = {}
        this._loaded = null
        this._pending = {}
        this.DataClass = DataClass

        repositorySubscriptions?.map(repositorySubscription => repositorySubscription(this))
    }

    _setItems (value) {
        this._items = value
    }

    _setAllLoaded (value) {
        this._allLoaded = value
    }

    get all () {
        return [...this._items]
    }

    get items () {
        return [...this._items]
    }

    get allLoaded () {
        return this._allLoaded
    }

    get allExtraData () {
        return this._allExtraData
    }

    _setAllExtraData (value) {
        this._allExtraData = value
    }

    clear () {
        this._setItems([])
        this._map = {}
        this._setAllLoaded(false)
        this._loaded = null
    }

    sort (comparer) {
        this._items.sort(comparer)
        return this._items
    }

    loadAll (transformResult, force) {
        return new Promise((resolve, reject) => {
            if (this.allLoaded && !force) {
                resolve(this.all)
            } else {
                this._loaded = this._get({}, transformResult)
                    .then(result => {
                        this._setAllLoaded(true)
                        this._setAllExtraData({
                            'totalCount': result.itemsTotalCount || 0,
                            'totalZeroSeatNetworkLicenseCount': result.itemsExtraData?.total_zero_seat_network_license_count || 0,
                            'userHasUoLicenses': result.itemsExtraData?.user_has_uo_licenses || false,
                            'userHasUoZeroSeatNetworkLicenses': result.itemsExtraData?.user_has_uo_zero_seat_network_licenses || false
                        })

                        resolve(this.all)
                    })
                    .catch(error => {
                        // We return directly the response here as it is already retrieved from the error in _get()
                        if (!error) {
                            // We are trying to catch errors coming from browser redirect BSD-8134
                            let msg = 'Repo.loadAll returned empty response.'
                            console.error(msg)
                            reject(new CanceledOperation(msg))
                            return
                        }
                        reject(error)
                    })
            }
        })
    }

    reloadAll () {
        this.clear()
        return this.loadAll(undefined, true)
    }

    loadById (uid, force) {
        if (!(uid in this._map) || force) {
            return this._get({id: uid})
        }

        return Promise.resolve(this._map[uid])
    }

    getFromCache (uid) {
        return this._map[uid]
    }

    getOrSet (data, parentId) {
        return this._saveItemData(data, parentId)
    }

    ensureLoaded (uid) {
        if (uid in this._map) {
            return
        }

        this.loadById(uid)
    }

    loadByFilter (filter, transformResult) {
        return this._get(filter, transformResult)
    }

    _saveData (data, parentId) {
        const results = data.results || [data]
        let items = []
        for (let i = 0; i < results.length; i++) {
            items.push(this._saveItemDataCore(results[i], parentId))
        }

        items.itemsCount = data.count
        items.itemsTotalCount = data.total_count
        items.itemsExtraData = data.extra_data

        // if called with a single data item return a single result
        return data.results ? items : items[0]
    }

    _deleteData (uid) {
        delete this._map[uid]

        let i = this._items.length
        while (i--) {
            // temporary while some data objects still use KO
            let itemId = this._getItemId(this._items[i])
            itemId = typeof itemId === 'function' ? itemId() : itemId
            if (itemId === uid) {
                this._items.splice(i, 1)
            }
        }
    }

    _saveItemData (data, parentId) {
        return this._saveItemDataCore(data, parentId)
    }

    _saveItemDataCore (data, parentId) {
        const itemId = this._getItemId(data)
        let item = this._map[itemId] || new this.DataClass(itemId, parentId)
        item._load(data)

        if (!(itemId in this._map)) {
            this._map[itemId] = item
            this._items.push(item)
        }

        return item
    }

    _getItemId (data) {
        // Note: if generateId is defined, then use it instead of item.id
        if (this.DataClass.generateId === undefined) {
            return data.id
        }

        return this.DataClass.generateId(data)
    }

    _buildUrl (filters) {
        const parsedUrl = parseUrl(this.baseUrl)

        let url = parsedUrl.url
        if (filters.id) {
            url += '/' + filters.id
        }

        let filterParams = {}
        for (let filter in filters) {
            if (filter !== 'id') {
                filterParams[filter] = filters[filter]
            }
        }

        if (Object.keys(filterParams).length) {
            url = addUrlQueryParams(url, filterParams)
        }

        return addUrlQueryParams(url, parsedUrl.params)
    }

    _get (filters, transformResult) {
        const url = this._buildUrl(filters || {})
        let result = this._pending[url]
        if (!result) {
            result = this._pending[url] = new Promise((resolve, reject) => {
                axios.get(url)
                    .then(data => {
                        data = data.data
                        if (transformResult) {
                            data = transformResult(data)
                        }

                        resolve(this._saveData(data))
                    })
                    .catch(error => {
                        reject(error)
                    })
                    .finally(() => {
                        delete this._pending[url]
                    })
            })
        }

        return result
    }

    create (data) {
        return this.save(null, data)
    }

    update (uid, data) {
        return this.save(uid, data)
    }

    save (uid, data) {
        const url = this._buildUrl({id: uid})
        return new Promise((resolve, reject) => {
            const options = {
                url: url,
                method: uid ? 'PUT' : 'POST',
                data: data,
                contentType: 'application/json'
            }

            axios(options)
                .then(response => {
                    resolve(this._saveData(response.data))
                })
                .catch(error => {
                    console.error('Failed to save data for ' + this.DataClass.name)
                    reject(error)
                })
        })
    }

    delete (uid) {
        const url = this._buildUrl({id: uid})
        return new Promise((resolve, reject) => {
            const options = {
                url: url,
                method: 'DELETE',
                contentType: 'application/json'
            }

            axios(options)
                .then(() => {
                    this._deleteData(uid)
                    resolve(uid)
                })
                .catch(error => {
                    console.error('Failed to delete data for ' + this.DataClass.name)
                    reject(error)
                })
        })
    }
}

export class NestedRepository {
    constructor (DataClass, baseUrl, repositorySubscriptions) {
        this.DataClass = DataClass
        this.baseUrl = baseUrl
        this._repos = {}
        this.repositorySubscriptions = repositorySubscriptions
    }

    for (...keys) {
        let result = this._repos[keys]
        if (!result) {
            result = new Repository(this.DataClass, this.baseUrl.apply(null, keys), this.repositorySubscriptions)
            this._repos[keys] = result
        }

        return result
    }
}
