export function isObjectEmpty (item) {
    return !!(typeof item === 'object' && Object.keys(item).length === 0)
}

function _getPageLanguageFromHtml () {
    const html = document.getElementsByTagName('html')[0]
    if (html) {
        return html.getAttribute('lang')
    }

    return ''
}

function _getPageLanguageFromMeta () {
    const metas = document.getElementsByTagName('meta')
    for (let i = 0; i < metas.length; i++) {
        let meta = metas[i]
        if ((meta.getAttribute('http-equiv') || '').toLowerCase() === 'content-language') {
            return meta.getAttribute('content')
        }
    }

    return ''
}

export function getPageLanguage () {
    return _getPageLanguageFromHtml() || _getPageLanguageFromMeta()
}

export function getParsedPageLanguage () {
    const language = getPageLanguage()
    const parts = (language || '').split('-')
    return {
        languageCode: parts[0] || 'en',
        countryCode: parts[1] || 'US'
    }
}
