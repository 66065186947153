import {makeObservable, action, computed} from 'mobx'
import {extendObservable} from './mobx-ko-proxy'
import Urls from 'urls'
import {Repository} from 'view-models/data/repository'

// TODO: Move this to more general place
const StaticConstants = {
    monthlyPeriodName: 'monthly',
    annualPeriodName: 'annually'
}

export class Product {
    constructor (id, data, distributorPrefix) {
        this.id = id

        makeObservable(this, {
            _load: action,
            name: computed,
            nonLocalizedPeriodName: computed,
            isPeriodic: computed,
            isMonthly: computed
        })

        extendObservable(this, {
            upc: null,
            type: null,
            productType: null,
            protectionType: null,
            typeName: null,
            period: null,
            maximumQuantity: null,
            isEditable: null,
            notice: null,
            title: null,
            description: null,
            distributorPrefix: null,
            defaultQuantity: null,
            quantityUnit: null,
            addOnCode: null,
            productLanguage: null,
            showProductLanguage: null,
            sku: null,
            data: null
        })

        if (data) {
            this._load(data, distributorPrefix)
        }
    }

    get name () {
        return this._getName()
    }

    get nonLocalizedPeriodName () {
        return this._getNonLocalizedPeriodName()
    }

    get isPeriodic () {
        return !!this.period
    }

    get isMonthly () {
        return this.period === '1m'
    }

    _load (data, distributorPrefix, productLanguage) {
        this.upc = data.upc || ''
        this.type = data.type || ''
        this.productType = data.product_type || ''
        this.protectionType = data.protection_type || ''
        this.typeName = data.type_name || ''
        this.period = data.period || ''
        this.maximumQuantity = data.maximum_quantity || null
        this.isEditable = !!data.is_editable
        this.notice = data.notice || null
        this.title = data.title || ''
        this.description = data.description || ''
        this.distributorPrefix = distributorPrefix
        this.defaultQuantity = data.default_quantity || null
        this.quantityUnit = data.quantity_unit_representation || null
        this.addOnCode = data.add_on_code || ''
        this.productLanguage = productLanguage || ''
        this.sku = data.sku || ''
        this.data = data.data || {}
    }

    static create (data, distributorPrefix, productLanguage) {
        const result = new Product(data.id)
        result._load(data, distributorPrefix, productLanguage)
        return result
    }

    _getName () {
        if (this.addOnCode === 'contract') {
            return 'Service Select'
        }

        if (this.productLanguage) {
            return `${this.title} (${this.productLanguage})`
        }

        return this.title
    }

    _getNonLocalizedPeriodName () {
        if (this.isMonthly) {
            return StaticConstants.monthlyPeriodName
        }
        return StaticConstants.annualPeriodName
    }
}

Product.repo = new Repository(Product, Urls.products)
