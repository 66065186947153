export default class PromiseQueue {
    constructor (completeHandler = () => {}) {
        this.queue = []
        this.workingOnPromise = false
        this.onComplete = completeHandler
    }

    enqueue (callback) {
        return new Promise((resolve, reject) => {
            this.queue.push({callback, resolve, reject})
            this.dequeue()
        })
    }

    dequeue () {
        if (this.workingOnPromise) {
            return
        }

        const item = this.queue.shift()
        if (!item) {
            this.onComplete()
            return
        }

        this.workingOnPromise = true
        item.callback()
            .then((value) => {
                item.resolve(value)
            })
            .catch(err => {
                item.reject(err)
            })
            .finally(() => {
                this.workingOnPromise = false
                this.dequeue()
            })
    }
}
