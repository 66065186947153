import {addUrlQueryParams} from './query-params'

export default {
    bogusIds: ['0000000', '000000', '00000'],
    bogusId: '0000000',
    secondBogusId: '000000',
    thirdBogusId: '00000',

    urlWithFilter: function (url) {
        return function (filtersData) {
            let resultUrl = url.slice()
            return filtersData ? addUrlQueryParams(resultUrl, filtersData) : resultUrl
        }
    },

    curriedUrl: function (url) {
        return function (...values) {
            return this.replaceIds(url, ...values)
        }.bind(this)
    },

    replaceIds: function (url, ...values) {
        const patterns = this.bogusIds.map(bogusId => new RegExp(`/${bogusId}`, 'g'))
        return values.reduce((r, value, index) => r.replace(patterns[index], this.addSlashAtStart(value)), url)
    },

    addSlashAtStart: function (text) {
        return '/' + text
    }
}
