import {i18n} from '@lingui/core'
import {bg, da, de, en, es, fi, fr, it, ja, nb, nl, pl, pt, pt_PT as ptPt, sv, zh} from 'make-plural/plurals'
import {messages as messagesBg} from './locale/bg/estore-basket.mjs'
import {messages as messagesDa} from './locale/da/estore-basket.mjs'
import {messages as messagesDe} from './locale/de/estore-basket.mjs'
import {messages as messagesEn} from './locale/en/estore-basket.mjs'
import {messages as messagesEnGb} from './locale/en-gb/estore-basket.mjs'
import {messages as messagesEs} from './locale/es/estore-basket.mjs'
import {messages as messagesFi} from './locale/fi/estore-basket.mjs'
import {messages as messagesFr} from './locale/fr/estore-basket.mjs'
import {messages as messagesIt} from './locale/it/estore-basket.mjs'
import {messages as messagesJa} from './locale/ja/estore-basket.mjs'
import {messages as messagesNb} from './locale/nb/estore-basket.mjs'
import {messages as messagesNl} from './locale/nl/estore-basket.mjs'
import {messages as messagesPl} from './locale/pl/estore-basket.mjs'
import {messages as messagesPt} from './locale/pt/estore-basket.mjs'
import {messages as messagesPtBr} from './locale/pt-br/estore-basket.mjs'
import {messages as messagesSv} from './locale/sv/estore-basket.mjs'
import {messages as messagesZhCn} from './locale/zh-cn/estore-basket.mjs'

export const defaultLocale = 'en'

// Required for template and plurals messages
i18n.loadLocaleData({
    bg: {plurals: bg},
    da: {plurals: da},
    de: {plurals: de},
    en: {plurals: en},
    'en-gb': {plurals: en},
    es: {plurals: es},
    fi: {plurals: fi},
    fr: {plurals: fr},
    it: {plurals: it},
    ja: {plurals: ja},
    nb: {plurals: nb},
    nl: {plurals: nl},
    pl: {plurals: pl},
    pt: {plurals: ptPt},
    'pt-br': {plurals: pt},
    sv: {plurals: sv},
    'zh-cn': {plurals: zh}
})

i18n.load({
    'bg': messagesBg,
    'da': messagesDa,
    'de': messagesDe,
    'en': messagesEn,
    'en-gb': messagesEnGb,
    'es': messagesEs,
    'fi': messagesFi,
    'fr': messagesFr,
    'it': messagesIt,
    'ja': messagesJa,
    'nb': messagesNb,
    'nl': messagesNl,
    'pl': messagesPl,
    'pt': messagesPt,
    'pt-br': messagesPtBr,
    'sv': messagesSv,
    'zh-cn': messagesZhCn
})
