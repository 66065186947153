import numeral from 'numeral'

import 'numeral/locales/de'
import 'numeral/locales/it'
import 'numeral/locales/da-dk'
import 'numeral/locales/pl'
import 'numeral/locales/fr'
import 'numeral/locales/nl-nl'
import 'numeral/locales/no'
import 'numeral/locales/fi'
import 'numeral/locales/es'
import 'numeral/locales/pt-pt'
import 'numeral/locales/chs'
import 'numeral/locales/ja'

window.numeral = numeral
numeral.register('locale', 'da', numeral.locales['da-dk'])
numeral.register('locale', 'nb', numeral.locales['no'])
numeral.register('locale', 'zh-cn', numeral.locales['chs'])
numeral.locales.en.delimiters.thousands = ''
numeral.locales.de.delimiters.thousands = '.'
numeral.locales.nb.delimiters.thousands = '.'
numeral.locales.fi.delimiters.thousands = '\xa0' // non-breaking space
numeral.locales.fr.delimiters.thousands = '\xa0'
numeral.locales.pl.delimiters.thousands = '\xa0'
