import ko from 'knockout'

import html from 'components/basket-messages.html'

class BasketMessagesViewModel {
    constructor (params) {
        params = params || {}
        this._basket = ko.observable(params.basket || null)
        this.basket = ko.pureComputed(() => ko.unwrap(this._basket()))
        this.isReadOnly = params.readOnly || false
    }
}

ko.components.register('VW.Components.BasketMessages', {
    viewModel: BasketMessagesViewModel,
    template: html
})
