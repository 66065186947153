import ko from 'knockout'
import {basketTranslations, lazyTrans} from 'components/translations'

import 'bindings/time-localization'
import 'bindings/popper'

ko.bindingHandlers.disablePageScroll = {
    init: function () {
        const body = document.getElementsByTagName('body')[0]
        body.originalOverflowStyle = body.style.overflow
    },
    update: function (element, valueAccessor) {
        var disableScroll = ko.unwrap(valueAccessor())

        const body = document.getElementsByTagName('body')[0]
        if (disableScroll) {
            body.originalOverflowStyle = body.style.overflow
            body.style.overflow = 'hidden'
        } else if (body.style.overflow) {
            body.style.overflow = body.originalOverflowStyle
        }
    }
}

const templatesMap = {
    phone: `<a href="tel:{phone}">{phone}</a>`,
    email: `<a href="mailto:{email}">{email}</a>`,
    price: `<span data-bind="component: {name: 'VW.Components.Price', params: {price: '{amount}', currency: '{currency}'}}"></span>`,
    discountedPrice: `<span class="strikethrough" data-bind="component: {name: 'VW.Components.Price', params: {price: '{amount}', currency: '{currency}'}}"></span> <span data-bind="component: {name: 'VW.Components.Price', params: {price: '{discountedAmount}', currency: '{currency}'}}"></span>`,
    date: `<span data-bind="textLocalizedDate: {value: '{date}', format: 'll'}"></span>`
}

// jsTrans: <messageKey> || {<messageKey>: {var1: '', var2: {template: <templateId>, params: {}}}
ko.bindingHandlers.jsTrans = {
    init: function () {
        return { controlsDescendantBindings: true }
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
        // remove old bindings from the content of the element
        // Based on KO v3.4.0 cleanNode: Clone the descendants list in case it changes during iteration
        let descendants = []
        ko.utils.arrayPushAll(descendants, element.getElementsByTagName('*'))
        for (let i = 0, j = descendants.length; i < j; i++) {
            ko.cleanNode(descendants[i])
        }

        const options = ko.unwrap(valueAccessor())
        let messageVars = {}
        let messageKey = typeof options === 'string' ? options : Object.keys(options)[0]
        let message = basketTranslations[messageKey]
        if (typeof message === 'function') {
            const calculatedMessageKey = message.apply(null, options[messageKey].getMessageKeyParams || [])
            message = basketTranslations[calculatedMessageKey]
        }

        if (message && typeof options === 'object') {
            for (const key in options[messageKey]) {
                let value = ko.unwrap(options[messageKey][key])

                if (typeof value === 'object') {
                    let template = templatesMap[value.template] || ''
                    if (!template && value.template && value.template.startsWith('#')) {
                        template = document.getElementById(value.template.substr(1)).innerHTML
                    }

                    for (const param in value.params) {
                        template = template.replaceAll(`{${param}}`, ko.unwrap(value.params[param]) || '')
                    }
                    messageVars[key] = template
                } else {
                    messageVars[key] = value
                }
            }
        }

        element.innerHTML = message ? lazyTrans(message, messageVars) : message
        ko.applyBindingsToDescendants(bindingContext, element)
    }
}
