import moment from 'moment'

// list all locales that we want included from webpack
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/da'
import 'moment/locale/sv'
import 'moment/locale/pl'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/nb'
import 'moment/locale/fi'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/zh-cn'
import 'moment/locale/ja'

window.moment = moment
