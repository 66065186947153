import { i18n } from '@lingui/core'
import {defineMessage, t} from '@lingui/macro'

// Note: Template messages/plurals should be marked with defineMessage macro, others work ok with t macro
export const basketTranslations = {
    groupNames: {
        products: t`Products`,
        contract: 'Maintenance',
        'monthly-subscription': t`Monthly Subscriptions`,
        'yearly-subscription': t`Annual Subscriptions`,
        training: 'Training',
        'co-term-existing': t`Prorated payments for existing subscriptions`
    },
    bagTitleMsg: t`Shopping Bag`,
    checkoutMsg: t`Checkout`,
    emptyBagMsg: t`Your bag is empty`,
    emptyBagContactsMsg: defineMessage({message: 'Questions? You can contact sales at {salesPhone}.'}),
    continueShoppingMsg: t`Continue Shopping`,
    serviceSelectMsg: 'Service Select',
    removeMsg: t`Remove`,
    unavailableMsg: t`Unavailable`,
    freeMsg: t`Free`,
    includedMsg: t`Included`,
    automaticPromotionsMsg: t`Automatic Promotions`,
    savedAmountMsg: defineMessage({message: 'You saved {amount}'}),
    unexpectedErrorMsg: t`An unexpected error occurred.`,
    unavailableProductsMsg: t`Your shopping bag contains one or more products that are currently unavailable in your market. Please remove them before continuing.`,
    monthPeriodMsg: t`month`,
    yearPeriodMsg: t`year`,
    coTermDescription: defineMessage({message: 'Payment for {days} days until {date}'}),
    willRenewMsg: defineMessage({message: 'will renew at {price} starting on {date}'}),
    willRenewPlusTaxMsg: defineMessage({message: 'will renew at {price} + Tax starting on {date}'}),
    willRenewCountMsg: defineMessage({message: '{count} licenses will renew at {price} starting on {date}'}),
    willRenewSingleMsg: defineMessage({message: '1 license will renew at {price} starting on {date}'}),
    willRenewAllMsg: defineMessage({message: 'all licenses will renew at {price} starting on {date}'}),
    getWillRenewMsg: (c, total) => c === null ? 'willRenewMsg' : (c === total ? 'willRenewAllMsg' : (c === 1 ? 'willRenewSingleMsg' : 'willRenewCountMsg')),
    applyVoucherMsg: t`apply`,
    promoCodeFieldMsg: t`Promo code`,
    enterPromoCodeMsg: t`Enter Promo Code`,
    organizationOnCoTermMsg: t`Your organization has signed up for Renewal Date Sync, which provides a unified renewal date for all your annual subscriptions. Your current payment amount has been prorated to account for this initial sync of your renewal date.`,
    initialCoTermMsg: defineMessage({message: 'initial payments adjusted for {renewalDateSyncTerm}'}),
    renewalDateSyncTerm: t`Renewal Date Sync`,
    networkLicenseSeat: t`network license seat`
}

export function lazyTrans (message, vars) {
    return i18n._(message || '', vars || {})
}
