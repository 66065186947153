import ko from 'knockout'
import { createPopper } from '@popperjs/core'

ko.bindingHandlers.popper = {
    init: function (element, valueAccessor) {
        function findTooltip () {
            let nextSibling = element.nextElementSibling
            while (nextSibling && !nextSibling.matches('.popper')) {
                nextSibling = nextSibling.nextElementSibling
            }
            return nextSibling
        }

        function show () {
            tooltip.classList.add('show')
            popperInstance.update()
        }

        function hide () {
            tooltip.classList.remove('show')
        }

        function toggle () {
            if (tooltip.classList.contains('show')) {
                hide()
            } else {
                show()
            }
        }

        let options = valueAccessor() || {}
        options['modifiers'] = [
            {
                name: 'offset',
                options: {
                    offset: [0, 12]
                }
            }
        ]

        const tooltip = findTooltip()
        const popperInstance = createPopper(element, tooltip, options)

        window.document.addEventListener('click', event => {
            if (tooltip.contains(event.target)) {
                return
            }

            if (element.contains(event.target)) {
                return toggle()
            }

            hide()
        })
    }
}
