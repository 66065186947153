import ko from 'knockout'

import html from 'components/basket-offer-discounts.html'

export class BasketOfferDiscountsViewModel {
    constructor (data) {
        this._basket = data.basket
        this.isReadOnly = data.readOnly || false
        this.basket = ko.pureComputed(() => ko.unwrap(this._basket))
        this.visible = ko.pureComputed(() => this.basket() && !this.basket().isEmpty && this.basket().offerDiscounts.length)
        this.offerDiscounts = ko.pureComputed(() => {
            return this.basket() && this.basket().offerDiscounts.length ? this.basket().offerDiscounts : null
        })
        // todo: this may only need to live here temporary, until we find a better way to display it
        this.systemDiscounts = ko.pureComputed(() => {
            return this.basket() && this.basket().systemDiscounts.length ? this.basket().systemDiscounts : null
        })
    }
}

ko.components.register('VW.Components.BasketOfferDiscounts', {
    viewModel: BasketOfferDiscountsViewModel,
    template: html
})
