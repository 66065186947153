import numeral from 'numeral'

export default class PriceUtils {
    static noDecimal (price) {
        return numeral(this._getNumber(price, true)).format('0,0', Math.floor)
    }

    static decimalOnly (price) {
        return numeral(this._getNumber(price, true)).format('.00').substring(1)
    }

    static nonZeroDecimalOnly (price) {
        return numeral(this._getNumber(price, true)).format('[.]00').substring(1)
    }

    static removeZeroDecimal (price) {
        return numeral(this._getNumber(price, true)).format('0,0[.]00')
    }

    static isEqual (price, otherPrice) {
        return numeral(this._getNumber(price, true)).value() === numeral(this._getNumber(otherPrice, true)).value()
    }

    static _getNumber (numberString, isPrice) {
        if (typeof numberString === 'string') {
            numberString = numberString.replace(/,/g, '') // DRF adds thousands separator, but JS doesn't know this
        }
        let result = Number(numberString)
        if (isPrice) {
            result = Math.round(result * 100) / 100
        }

        return result
    }
}
