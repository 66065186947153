import ko from 'knockout'
import {getLocalizedDate} from 'utils/date'

ko.bindingHandlers.textLocalizedDate = {
    update: function (element, valueAccessor) {
        let value = ko.unwrap(valueAccessor())
        if (!value) {
            return
        }

        value = ko.unwrap(value.value || value)
        const format = ko.unwrap(valueAccessor().format || 'll')
        const isLocalTime = ko.unwrap(valueAccessor().localTime || false)

        element.textContent = getLocalizedDate(value, format, isLocalTime)
    }
}
