import config from './config'
import urlUtils from 'utils/urls'

const urls = {
    basket: '/api/v1/estore/basket',
    getBasketLine: urlUtils.curriedUrl(
        `/api/v1/estore/baskets/${urlUtils.bogusId}/lines/${urlUtils.secondBogusId}`),
    basketAddProduct: '/api/v1/estore/basket/add-product',
    basketAddVoucher: '/api/v1/estore/basket/add-voucher',
    basketRemoveVoucher: '/api/v1/estore/basket/remove-voucher',
    basketPrepareForSubmission: urlUtils.curriedUrl(
        `/api/v1/estore/baskets/${urlUtils.bogusId}/prepare-for-submission`),
    checkout: '/api/v1/estore/checkout',
    checkoutPage: '/checkout',
    getBasketMessageDetail: urlUtils.curriedUrl(
        `/api/v1/estore/baskets/${urlUtils.bogusId}/messages/${urlUtils.secondBogusId}`),
    countryLanguageSet: '/api/v1/country-languages/@set',
    countryLanguageCurrent: '/api/v1/country-languages/@current',
    removeAllProducts: '/api/v1/estore/basket/remove-all-products'
}

for (const key in urls) {
    if (typeof urls[key] === 'function') {
        const original = urls[key]
        urls[key] = function () { return config.siteUrl + original.apply(null, arguments) }
    } else {
        urls[key] = config.siteUrl + urls[key]
    }
}

export default urls
