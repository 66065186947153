import ko from 'knockout'

import html from 'components/basket-group-voucher.html'

export class BasketGroupVoucherViewModel {
    constructor (data) {
        // TODO: The promo code section should be visible only when there are available active promo codes
        this.groupType = 'voucher'
        this._basket = data.basket
        this.basket = ko.pureComputed(() => ko.unwrap(this._basket))
        this.isReadOnly = data.readOnly || false
        this.voucher = ko.observable(this.basket().voucherToApply)
        this.appliedVouchers = ko.pureComputed(() => {
            return this.basket() && this.basket().voucherDiscounts ? this.basket().voucherDiscounts : []
        })

        this.showWhenReadOnly = ko.pureComputed(() => {
            return this.appliedVouchers() && this.appliedVouchers().length > 0
        })

        this.visible = ko.pureComputed(() => {
            return this.basket() && !this.basket().isEmpty && (!this.isReadOnly || this.showWhenReadOnly())
        })
    }

    addVoucher () {
        if (!this.basket()) {
            return
        }

        return this.basket().addVoucher(this.voucher())
    }

    removeVoucher (voucher) {
        if (!this.basket()) {
            return
        }

        return this.basket().removeVoucher(voucher)
    }
}

ko.components.register('VW.Components.BasketGroupVoucher', {
    viewModel: BasketGroupVoucherViewModel,
    template: html
})
