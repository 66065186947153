import ko from 'knockout'
import moment from 'moment'

export function getLocalizedDate (dateStr, format = 'll', isLocalTime = false) {
    dateStr = ko.unwrap(dateStr)
    if (!dateStr) {
        return ''
    }

    const date = isLocalTime ? moment(dateStr).toDate() : moment.utc(dateStr).toDate()
    return moment(date).format(format)
}

export function formatLocalizedDateToISO (dateStr, format = 'L') {
    return dateStr
        ? moment(dateStr, moment.localeData().longDateFormat(format)).format('YYYY-MM-DD')
        : null
}

export const getRenewalSyncTimezoneOffset = () => {
    // We are trying to pick the best timezone offset for renewal syncs

    const currentOffset = new Date().getTimezoneOffset()
    let offset = 600
    if (currentOffset > 600) {
        // For clients with UTC-11 and before we want to make it choose 00:00 in their time (those timezones are not using DST)
        offset = currentOffset
    } else if (currentOffset <= -840) {
        // For clients with UTC+14 and after we want to make it choose 23:00 in their time (those timezones are not using DST)
        offset -= Math.abs(currentOffset) - 780
    }

    return offset
}
